import {
  AddressConfig,
  ApartmentConfig,
  ApartmentContractConfig,
  ApartmentVacancyNotationConfig,
  BlockContractConfig,
  BrfApartmentConfig,
  BrfCompanyConfig,
  BuildingConfig,
  CommonAreaConfig,
  CommunityConfig,
  CompanyConfig,
  ExternalClientConfig,
  IndustrialPremisesConfig,
  IndustrialPremisesContractConfig,
  IndustrialPremisesVacancyNotationConfig,
  InvoiceConfig,
  OrganizationUserConfig,
  OtherContractConfig,
  OutdoorAreaConfig,
  OutdoorSectionConfig,
  OutdoorSectionContractConfig,
  OutdoorSectionVacancyNotationConfig,
  ParkingLotConfig,
  ParkingSpotConfig,
  ParkingSpotContractConfig,
  ParkingSpotVacancyNotationConfig,
  PriceGroupConfig,
  RealEstateConfig,
  RegionConfig,
  SegmentConfig,
  SensorConfig,
  SensorSpecificationConfig,
  TenantConfig,
  VacancyInvoiceConfig,
  type FieldValue,
  type ModelName,
} from '@pigello/pigello-matrix';
import queryString from 'query-string';

export const Urls = {
  createUser: {
    base: '/create-user/base',
    kind: '/create-user/kind',
    workengagements: '/create-user/workengagements',
    summary: '/create-user/summary',
  },
  inviteTenants: {
    filters: (realEstateIds: string[]) =>
      `/invite-tenants/filters${
        realEstateIds?.length > 0
          ? `?realEstateIds=${realEstateIds.join(',')}`
          : ''
      }`,
    summary: '/invite-tenants/summary',
  },
  revenueIncrease: {
    targets: '/revenue-increase/perform/targets',
    settings: '/revenue-increase/perform/settings',
    increases: '/revenue-increase/perform/increases',
  },
  imdimport: {
    file: '/imd-import/file',

    mapsensors: '/imd-import/map-sensors',
  },

  detailedLogs: {
    overview: (modelName: string, id: string) =>
      `/detailed-logs/${modelName}/${id}`,
  },
  brfFlows: {
    transactionDeclaration: {
      generate: (year: string, brfCompanyId: string) =>
        `/brf/transaction-declaration/${brfCompanyId}/${year}/generate`,
      overview: (year: string, brfCompanyId: string) =>
        `/brf/transaction-declaration/${brfCompanyId}/${year}/overview`,
      handleSingle: (brfCompanyId: string, year: string, id: string) =>
        `/brf/transaction-declaration/handle/${brfCompanyId}/${year}/${id}`,
    },
    transaction: {
      create: (brfApartmentId: string) =>
        `/brf/transaction/create/${brfApartmentId}`,
    },
    invoicingContract: {
      create: (brfApartmentId: string) =>
        `/brf/invoicing-contract/create/${brfApartmentId}`,

      handle: (brfApartmentInvoicingConfigurationId: string) =>
        `/brf/invoicing-contract/handle/${brfApartmentInvoicingConfigurationId}`,
    },
    quotaCollection: {
      create: {
        settings: (brfId: string) =>
          `/brf/quotacollection/create/settings?brf=${brfId}`,

        quotas: (brfId: string) =>
          `/brf/quotacollection/create/quotas?brf=${brfId}`,
        summary: (brfId: string) =>
          `/brf/quotacollection/create/summary?brf=${brfId}`,
      },
      edit: {
        quotas: (collectionId: string) =>
          `/brf/quotacollection/edit/quotas?collection=${collectionId}`,
        summary: (collectionId: string) =>
          `/brf/quotacollection/edit/summary?collection=${collectionId}`,
      },
    },
    fee: {
      create: (brfCompanyId: string) => `/brf/fee/handle?brf=${brfCompanyId}`,
      edit: (brfCompanyId: string, feeId: string) =>
        `/brf/fee/handle?brf=${brfCompanyId}&id=${feeId}`,
    },
    debt: {
      create: (brfCompanyId: string) => `/brf/debt/handle?brf=${brfCompanyId}`,
      edit: (brfCompanyId: string, debtId: string) =>
        `/brf/debt/handle?brf=${brfCompanyId}&id=${debtId}`,
    },
    capitalContribution: {
      create: {
        settings: (brfCompanyId: string) =>
          `/brf/capital-contribution/create/settings?brf=${brfCompanyId}`,
        distribution: (brfCompanyId: string) =>
          `/brf/capital-contribution/create/distribution?brf=${brfCompanyId}`,
        summary: (brfCompanyId: string) =>
          `/brf/capital-contribution/create/summary?brf=${brfCompanyId}`,
      },
      edit: (collectionId: string) =>
        `/brf/capital-contribution/edit/${collectionId}`,
    },
  },
  notification: {
    overview: (id: string) => `/notification/${id}`,
  },
  documents: {
    overview: '/documents/folders',
    folder: (id: string) => `/documents/folders/${id}`,
    pdf: (id: string, modelName: ModelName) =>
      `/documents/pdf/${modelName}/${id}`,
  },
  dashboard: {
    reports: {
      report: ({
        kind,
        templateId,
        basedOn,
        variant,
      }: {
        kind: string;
        // only one of these three should be passed
        templateId?: string; // id of reporttemplate
        basedOn?: string; // id of reportresult
        variant?: string; // internalid from constants in report folder
      }) =>
        `/dashboard/reports/report/${kind}${templateId ? `?template=${templateId}` : ''}${basedOn ? `?basedon=${basedOn}` : ''}${variant ? `?variant=${variant}` : ''}`,
      available: '/dashboard/reports/available-reports',
      myTemplates: '/dashboard/reports/created-templates',
      sharedTemplates: '/dashboard/reports/shared-templates',
      performed: '/dashboard/reports/performed',
    },
    bookmarks: '/dashboard/bookmarks',
    getStarted: '/dashboard/get-started',
    home: '/dashboard/my-workday',
    inbox: {
      overview: '/dashboard/inbox',
      signings: '/dashboard/inbox/pending-signings',
      approvals: '/dashboard/inbox/pending-approvals',
      performs: '/dashboard/inbox/pending-performs',
      retroactivity: '/dashboard/inbox/retroactivity-proposals',
      distribution: '/dashboard/inbox/distribution-proposals',
      retroactivityModel: (modelName: ModelName) =>
        `/dashboard/inbox/retroactivity-proposals/${modelName}`,
      approvalmodel: (modelName: ModelName) =>
        `/dashboard/inbox/pending-approvals/${modelName}`,
    },
    companies: '/dashboard/companies',
    brfCompanies: '/dashboard/brf-associations',
    externalclients: '/dashboard/external-clients',
    structural: {
      regions: '/dashboard/structural/regions',
      communities: '/dashboard/structural/communities',
      segments: '/dashboard/structural/segments',
      realestates: '/dashboard/structural/realestates',
      outdoorareas: '/dashboard/structural/outdoorareas',
      buildings: '/dashboard/structural/buildings',
      parkinglots: '/dashboard/structural/parking-lots',
      addresses: '/dashboard/structural/addresses',
    },
    imd: {
      overview: '/dashboard/imd/overview',
      sensorspecifications: '/dashboard/imd/sensor-specifications',
      sensors: '/dashboard/imd/sensors',
      pricegroups: '/dashboard/imd/price-groups',
      importdata: '/dashboard/imd/import-data',
    },
    apartments: '/dashboard/apartments',
    industrialPremises: '/dashboard/industrial-premises',
    outdoorSections: '/dashboard/outdoor-sections',
    parkingSpots: '/dashboard/parking-spots',
    commonAreas: '/dashboard/common-areas',
    brf: {
      brfApartments: '/dashboard/brf-apartments',
    },
    contracts: {
      apartment: '/dashboard/contracts/apartment',
      apartmentdrafts: '/dashboard/contracts/apartment/drafts',
      industrialPremises: '/dashboard/contracts/industrial-premises',
      industrialPremisesdrafts:
        '/dashboard/contracts/industrial-premises/drafts',
      parkingSpot: '/dashboard/contracts/parking-spot',
      parkingSpotdrafts: '/dashboard/contracts/parking-spot/drafts',
      outdoorSection: '/dashboard/contracts/outdoor-section',
      outdoorSectiondrafts: '/dashboard/contracts/outdoor-section/drafts',
      otherContracts: '/dashboard/contracts/other-contracts',
      otherContractdrafts: '/dashboard/contracts/other-contracts/drafts',
      blockContracts: '/dashboard/contracts/block-contracts',
      blockContractdrafts: '/dashboard/contracts/block-contracts/drafts',
    },
    vacancyContracts: {
      apartment: '/dashboard/vacancy-contracts/apartment',
      industrialPremises: '/dashboard/vacancy-contracts/industrial-premises',
      outdoorSection: '/dashboard/vacancy-contracts/outdoor-section',
      parkingSpot: '/dashboard/vacancy-contracts/parking-spot',
    },
    digitalSigning: {
      processes: (modelName: ModelName) =>
        `/dashboard/digital-signing/processes?modelname=${modelName}`,
      templates: '/dashboard/digital-signing/templates',
      templatesForModelName: (modelName: ModelName) =>
        `/dashboard/digital-signing/templates/${modelName}`,
    },
    tenants: '/dashboard/tenants',
    brfMembers: '/dashboard/brf-members',
    invoicing: {
      overview: '/dashboard/invoicing/overview',
      status: '/dashboard/invoicing/status',
      companyStatus: '/dashboard/invoicing/status/companies',
      realestateStatus: '/dashboard/invoicing/status/realestates',
      failedDelivieries: '/dashboard/invoicing/failed-deliveries',
      failedMerges: '/dashboard/invoicing/status/failed-merges',
      invoices: '/dashboard/invoicing/invoices',
      accounting: '/dashboard/invoicing/accounting',
      accounts: '/dashboard/invoicing/accounts',
      payments: '/dashboard/invoicing/payments',
      verificationGroups: '/dashboard/invoicing/verification-groups',
      bookkeepingExport: '/dashboard/invoicing/bookkeeping-export',
      vacancyInvoices: '/dashboard/invoicing/vacancy-invoices',
      failedAutogiroTransactionAttempts:
        '/dashboard/invoicing/failed-autogiro-transaction-attempts',
      pendingAutogiroTransactionAttempts:
        '/dashboard/invoicing/pending-autogiro-transaction-attempts',
    },
    market: {
      pipes: '/dashboard/market/pipes',
      ads: '/dashboard/market/ads',
      leads: '/dashboard/market/leads',
      marketplace: '/dashboard/market/marketplace',
    },
    technicalManagement: {
      errands: '/dashboard/technical-management/errands',
      inspections: '/dashboard/technical-management/inspections',
      rounding: '/dashboard/technical-management/rounding',
      gov: '/dashboard/technical-management/gov',
    },
    keyhandling: {
      keys: '/dashboard/key-handling/keys',
      receipts: '/dashboard/key-handling/receipts',
    },
    sendouts: '/dashboard/sendouts',
    renovations: {
      overview: '/dashboard/renovations/overview',
      structural: '/dashboard/renovations/structural',
      spaces: '/dashboard/renovations/spaces',
    },
    revenue: {
      distribution: {
        default: '/dashboard/revenue/distribution',
        create: '/dashboard/revenue/distribution/create',
        detail: {
          overview: (id: FieldValue<string>) =>
            `/dashboard/revenue/distribution/${id}/overview`,
          values: (id: FieldValue<string>) =>
            `/dashboard/revenue/distribution/${id}/distributed-values`,
          quotas: (id: FieldValue<string>) =>
            `/dashboard/revenue/distribution/${id}/quotas`,
          settings: (id: FieldValue<string>) =>
            `/dashboard/revenue/distribution/${id}/settings`,
          taxationSettings: (id: FieldValue<string>) =>
            `/dashboard/revenue/distribution/${id}/taxation-connection-settings`,
        },
      },
      indexation: {
        default: '/dashboard/revenue/indexation',
        settings: {
          default: '/dashboard/revenue/indexation/settings',
          detail: (id: FieldValue<string>) =>
            `/dashboard/revenue/indexation/settings/${id}`,
        },
        tables: {
          default: '/dashboard/revenue/indexation/tables',
          create: '/dashboard/revenue/indexation/tables/create',
          detail: (id: FieldValue<string>) =>
            `/dashboard/revenue/indexation/tables/${id}`,
        },
      },
      debitRows: {
        apartment: '/dashboard/revenue/debit-rows/apartment',
        industrialPremises: '/dashboard/revenue/debit-rows/industrial-premises',
        parkingSpot: '/dashboard/revenue/debit-rows/parking-spot',
        outdoorSection: '/dashboard/revenue/debit-rows/outdoor-section',
        brf: '/dashboard/revenue/debit-rows/brf',
        apartmentContract: '/dashboard/revenue/debit-rows/apartment-contract',
        industrialPremisesContract:
          '/dashboard/revenue/debit-rows/industrial-premises-contract',
        parkingSpotContract:
          '/dashboard/revenue/debit-rows/parking-spot-contract',
        outdoorSectionContract:
          '/dashboard/revenue/debit-rows/outdoor-section-contract',
        othercontract: '/dashboard/revenue/debit-rows/other-contract',
      },
      taxation: {
        overview: '/dashboard/revenue/taxation/records',

        record: {
          detail: (id: FieldValue<string>) =>
            `/dashboard/revenue/taxation/records/${id}`,
        },
      },
      revenueIncrease: {
        overview: '/dashboard/revenue/revenue-increase',
      },
      retroactivity: {
        overview: '/dashboard/revenue/retroactivity',
        default:
          '/dashboard/revenue/retroactivity/retroactiveapartmentrevenueproposal',
      },
      customerEarnings: {
        settings: {
          default: '/dashboard/revenue/customer-earnings',
          detail: (id: FieldValue<string>) =>
            `/dashboard/revenue/customer-earnings/${id}`,
        },
      },
    },
  },
  detail: {
    externalClient: {
      overview: (id: FieldValue<string>) => `/detail/externalclient/${id}`,
      resources: (id: FieldValue<string>) =>
        `/detail/externalclient/${id}/resources`,
      companies: (id: FieldValue<string>) =>
        `/detail/externalclient/${id}/companies`,
      realestates: (id: FieldValue<string>) =>
        `/detail/externalclient/${id}/realestates`,
      buildings: (id: FieldValue<string>) =>
        `/detail/externalclient/${id}/buildings`,
      outdoorareas: (id: FieldValue<string>) =>
        `/detail/externalclient/${id}/outdoorareas`,
      parkinglots: (id: FieldValue<string>) =>
        `/detail/externalclient/${id}/parkinglots`,
      commonareas: (id: FieldValue<string>) =>
        `/detail/externalclient/${id}/commonareas`,
      apartments: (id: FieldValue<string>) =>
        `/detail/externalclient/${id}/apartments`,
      industrialpremises: (id: FieldValue<string>) =>
        `/detail/externalclient/${id}/industrialpremises`,
      outdoorsections: (id: FieldValue<string>) =>
        `/detail/externalclient/${id}/outdoorsections`,
      parkingspots: (id: FieldValue<string>) =>
        `/detail/externalclient/${id}/parkingspots`,
      tenants: (id: FieldValue<string>) =>
        `/detail/externalclient/${id}/tenants`,
    },
    address: {
      overview: (id: FieldValue<string>) => `/detail/address/${id}`,
      realestates: (id: FieldValue<string>) =>
        `/detail/address/${id}/realestates`,
      communities: (id: FieldValue<string>) =>
        `/detail/address/${id}/communities`,
      regions: (id: FieldValue<string>) => `/detail/address/${id}/regions`,
      buildings: (id: FieldValue<string>) => `/detail/address/${id}/buildings`,
      outdoorareas: (id: FieldValue<string>) =>
        `/detail/address/${id}/outdoorareas`,
      parkinglots: (id: FieldValue<string>) =>
        `/detail/address/${id}/parkinglots`,
      apartments: (id: FieldValue<string>) =>
        `/detail/address/${id}/apartments`,
      industrialpremises: (id: FieldValue<string>) =>
        `/detail/address/${id}/industrialpremises`,
      commonareas: (id: FieldValue<string>) =>
        `/detail/address/${id}/commonareas`,
      parkingspots: (id: FieldValue<string>) =>
        `/detail/address/${id}/parkingspots`,
      outdoorsections: (id: FieldValue<string>) =>
        `/detail/address/${id}/outdoorsections`,
    },
    customerColumns: (modelName: ModelName, id: FieldValue<string>) =>
      `/detail/${modelName}/${id}/customercolumns`,
    documents: (modelName: ModelName, id: FieldValue<string>) =>
      `/detail/${modelName}/${id}/documents`,
    events: (modelName: ModelName, id: FieldValue<string>) =>
      `/detail/${modelName}/${id}/events`,
    monitoring: (modelName: ModelName, id: FieldValue<string>) =>
      `/detail/${modelName}/${id}/monitoring`,
    notes: (modelName: ModelName, id: FieldValue<string>) =>
      `/detail/${modelName}/${id}/notes`,
    renovations: (modelName: ModelName, id: FieldValue<string>) =>
      `/detail/${modelName}/${id}/renovations`,
    imd: (modelName: ModelName, id: FieldValue<string>) =>
      `/detail/${modelName}/${id}/imd`,
    contract: {
      overview: (modelName: ModelName, id: FieldValue<string>) =>
        `/detail/${modelName}/${id}`,
      revenue: {
        overview: (modelName: ModelName, id: FieldValue<string>) =>
          `/detail/${modelName}/${id}/revenue-overview`,
        overviewdev: (modelName: ModelName, id: FieldValue<string>) =>
          `/detail/${modelName}/${id}/revenue-overview-dev`,
        indexation: (modelName: ModelName, id: FieldValue<string>) =>
          `/detail/${modelName}/${id}/revenue-indexation`,
        retroactivity: (modelName: ModelName, id: FieldValue<string>) =>
          `/detail/${modelName}/${id}/revenue-retroactivity`,
        increase: (modelName: ModelName, id: FieldValue<string>) =>
          `/detail/${modelName}/${id}/revenue-increase`,
        customerEarnings: (modelName: ModelName, id: FieldValue<string>) =>
          `/detail/${modelName}/${id}/customer-earnings`,
      },
      subcontracts: (modelName: ModelName, id: FieldValue<string>) =>
        `/detail/${modelName}/${id}/subcontracts`,
      othercontracts: (modelName: ModelName, id: FieldValue<string>) =>
        `/detail/${modelName}/${id}/othercontracts`,
      invoicing: {
        invoices: (modelName: ModelName, id: FieldValue<string>) =>
          `/detail/${modelName}/${id}/invoices`,
        payments: (modelName: ModelName, id: FieldValue<string>) =>
          `/detail/${modelName}/${id}/payments`,
        settings: (modelName: ModelName, id: FieldValue<string>) =>
          `/detail/${modelName}/${id}/invoicing-settings`,
      },
      signing: (modelName: ModelName, id: FieldValue<string>) =>
        `/detail/${modelName}/${id}/signing`,
      termination: (modelName: ModelName, id: FieldValue<string>) =>
        `/detail/${modelName}/${id}/termination`,
    },
    vacancyContract: {
      overview: (modelName: ModelName, id: string) =>
        `/detail/${modelName}/${id}`,
      vacancyinvoices: (modelName: ModelName, id: string) =>
        `/detail/${modelName}/${id}/vacancy-invoices`,
      lossnotations: (modelName: ModelName, id: string) =>
        `/detail/${modelName}/${id}/loss-notations`,
    },
    sensor: {
      overview: (id: FieldValue<string>) => `/detail/sensor/${id}`,
    },
    pricegroup: {
      overview: (id: FieldValue<string>) => `/detail/pricegroup/${id}`,
    },
    sensorspecification: {
      overview: (id: FieldValue<string>) => `/detail/sensorspecification/${id}`,
      sensors: (id: FieldValue<string>) =>
        `/detail/sensorspecification/${id}/sensors`,
      pricegroup: (id: FieldValue<string>) =>
        `/detail/sensorspecification/${id}/pricegroup`,
    },
    apartment: {
      overview: (id: FieldValue<string>) => `/detail/apartment/${id}`,
      revenue: {
        distribution: (id: FieldValue<string>) =>
          `/detail/apartment/${id}/revenue-distribution`,
        overview: (id: FieldValue<string>) =>
          `/detail/apartment/${id}/revenue-overview`,
        increase: (id: FieldValue<string>) =>
          `/detail/apartment/${id}/revenue-increase`,
      },
      renting: (id: FieldValue<string>) => `/detail/apartment/${id}/renting`,
      contracts: (id: FieldValue<string>) =>
        `/detail/apartment/${id}/contracts`,
      othercontracts: (id: FieldValue<string>) =>
        `/detail/apartment/${id}/othercontracts`,

      monitoring: (id: FieldValue<string>) =>
        `/detail/apartment/${id}/monitoring`,
    },
    brfApartment: {
      overview: (id: FieldValue<string>) => `/detail/brfapartment/${id}`,
      transactions: (id: FieldValue<string>) =>
        `/detail/brfapartment/${id}/transactions`,
      owners: (id: FieldValue<string>) => `/detail/brfapartment/${id}/owners`,
      invoicingSettings: (id: FieldValue<string>) =>
        `/detail/brfapartment/${id}/invoicing-settings`,
      invoices: (id: FieldValue<string>) =>
        `/detail/brfapartment/${id}/invoices`,
      debitGenerations: (id: FieldValue<string>) =>
        `/detail/brfapartment/${id}/debit-generations`,
      subcontracts: (id: FieldValue<string>) =>
        `/detail/brfapartment/${id}/subcontracts`,
      revenue: {
        overview: (id: FieldValue<string>) =>
          `/detail/brfapartment/${id}/revenue-overview`,
        fees: (id: FieldValue<string>) =>
          `/detail/brfapartment/${id}/revenue-fees`,
        distribution: (id: FieldValue<string>) =>
          `/detail/brfapartment/${id}/revenue-distribution`,
      },
      economy: {
        quotas: (id: FieldValue<string>) => `/detail/brfapartment/${id}/quotas`,
        pledges: (id: FieldValue<string>) =>
          `/detail/brfapartment/${id}/pledges`,
        reparationFund: (id: FieldValue<string>) =>
          `/detail/brfapartment/${id}/reparation-fund`,
        capitalContributions: (id: FieldValue<string>) =>
          `/detail/brfapartment/${id}/capital-contributions`,
      },
      realtorImage: (id: FieldValue<string>) =>
        `/detail/brfapartment/${id}/realtor-image`,
      othercontracts: (id: FieldValue<string>) =>
        `/detail/brfapartment/${id}/othercontracts`,
      monitoring: (id: FieldValue<string>) =>
        `/detail/brfapartment/${id}/monitoring`,
    },
    company: {
      overview: (id: FieldValue<string>) => `/detail/company/${id}`,
      realestates: (id: FieldValue<string>) =>
        `/detail/company/${id}/realestates`,
      rentnotifying: {
        settings: (id: FieldValue<string>) =>
          `/detail/company/${id}/rentnotifying-settings`,

        paymentmethods: (id: FieldValue<string>) =>
          `/detail/company/${id}/rentnotifying-paymentmethods`,
        contractinvoicing: (id: FieldValue<string>) =>
          `/detail/company/${id}/rentnotifying-contractinvoicing`,
        paymentterms: (id: FieldValue<string>) =>
          `/detail/company/${id}/rentnotifying-paymentterms`,
      },
    },
    brfCompany: {
      overview: (id: FieldValue<string>) => `/detail/brfcompany/${id}`,
      members: (id: FieldValue<string>) => `/detail/brfcompany/${id}/members`,
      brfApartments: (id: FieldValue<string>) =>
        `/detail/brfcompany/${id}/brfapartments`,
      quotas: (id: FieldValue<string>) => `/detail/brfcompany/${id}/quotas`,
      quotaOverview: (id: FieldValue<string>) =>
        `/detail/brfcompany/${id}/quota-overview`,
      fees: (id: FieldValue<string>) => `/detail/brfcompany/${id}/fees`,
      debt: (id: FieldValue<string>) => `/detail/brfcompany/${id}/debt`,
      capitalContributions: (id: FieldValue<string>) =>
        `/detail/brfcompany/${id}/capital-contributions`,
      financialPlan: (id: FieldValue<string>) =>
        `/detail/brfcompany/${id}/financial-plan`,
      financialReports: (id: FieldValue<string>) =>
        `/detail/brfcompany/${id}/financial-reports`,
      statutes: (id: FieldValue<string>) => `/detail/brfcompany/${id}/statutes`,
      pledges: (id: FieldValue<string>) => `/detail/brfcompany/${id}/pledges`,
      realtorImages: (id: FieldValue<string>) =>
        `/detail/brfcompany/${id}/realtor-images`,
      reparationFund: (id: FieldValue<string>) =>
        `/detail/brfcompany/${id}/reparation-fund`,
      transactionsFees: (id: FieldValue<string>) =>
        `/detail/brfcompany/${id}/transaction-fees`,
      realestates: (id: FieldValue<string>) =>
        `/detail/brfcompany/${id}/realestates`,
      ku55: (id: FieldValue<string>) => `/detail/brfcompany/${id}/ku55`,
      rentnotifying: {
        settings: (id: FieldValue<string>) =>
          `/detail/brfcompany/${id}/rentnotifying-settings`,

        paymentmethods: (id: FieldValue<string>) =>
          `/detail/brfcompany/${id}/rentnotifying-paymentmethods`,
        contractinvoicing: (id: FieldValue<string>) =>
          `/detail/brfcompany/${id}/rentnotifying-contractinvoicing`,
        paymentterms: (id: FieldValue<string>) =>
          `/detail/brfcompany/${id}/rentnotifying-paymentterms`,
      },
      rentableObjects: {
        apartments: (id: FieldValue<string>) =>
          `/detail/brfcompany/${id}/rentable-apartments`,
        industrialPremises: (id: FieldValue<string>) =>
          `/detail/brfcompany/${id}/industrial-premises`,
        outdoorSections: (id: FieldValue<string>) =>
          `/detail/brfcompany/${id}/outdoor-sections`,
        parkingSpots: (id: FieldValue<string>) =>
          `/detail/brfcompany/${id}/parking-spots`,
      },
      contracts: {
        apartments: (id: FieldValue<string>) =>
          `/detail/brfcompany/${id}/apartment-contracts`,
        industrialPremises: (id: FieldValue<string>) =>
          `/detail/brfcompany/${id}/industrial-premises-contracts`,
        outdoorSections: (id: FieldValue<string>) =>
          `/detail/brfcompany/${id}/outdoor-section-contracts`,
        parkingSpots: (id: FieldValue<string>) =>
          `/detail/brfcompany/${id}/parking-spot-contracts`,
        other: (id: FieldValue<string>) =>
          `/detail/brfcompany/${id}/other-contracts`,
      },
    },
    building: {
      overview: (id: FieldValue<string>) => `/detail/building/${id}`,
      commonareas: (id: FieldValue<string>) =>
        `/detail/building/${id}/commonareas`,
      apartments: (id: FieldValue<string>) =>
        `/detail/building/${id}/apartments`,
      brfApartments: (id: FieldValue<string>) =>
        `/detail/building/${id}/brfapartments`,
      industrialpremises: (id: FieldValue<string>) =>
        `/detail/building/${id}/industrialpremises`,
      parkingspots: (id: FieldValue<string>) =>
        `/detail/building/${id}/parkingspots`,
    },
    commonarea: {
      overview: (id: FieldValue<string>) => `/detail/commonarea/${id}`,
      othercontracts: (id: FieldValue<string>) =>
        `/detail/commonarea/${id}/othercontracts`,
    },
    community: {
      overview: (id: FieldValue<string>) => `/detail/community/${id}`,
      realestates: (id: FieldValue<string>) =>
        `/detail/community/${id}/realestates`,
    },
    industrialpremises: {
      overview: (id: FieldValue<string>) => `/detail/industrialpremises/${id}`,
      revenue: {
        distribution: (id: FieldValue<string>) =>
          `/detail/industrialpremises/${id}/revenue-distribution`,
        overview: (id: FieldValue<string>) =>
          `/detail/industrialpremises/${id}/revenue-overview`,
        increase: (id: FieldValue<string>) =>
          `/detail/industrialpremises/${id}/revenue-increase`,
      },
      contracts: (id: FieldValue<string>) =>
        `/detail/industrialpremises/${id}/contracts`,
      othercontracts: (id: FieldValue<string>) =>
        `/detail/industrialpremises/${id}/othercontracts`,
      renting: (id: FieldValue<string>) =>
        `/detail/industrialpremises/${id}/renting`,

      monitoring: (id: FieldValue<string>) =>
        `/detail/industrialpremises/${id}/monitoring`,
    },
    outdoorarea: {
      overview: (id: FieldValue<string>) => `/detail/outdoorarea/${id}`,
      outdoorsections: (id: FieldValue<string>) =>
        `/detail/outdoorarea/${id}/outdoorsections`,
      parkingspots: (id: FieldValue<string>) =>
        `/detail/outdoorarea/${id}/parkingspots`,
    },
    outdoorsection: {
      overview: (id: FieldValue<string>) => `/detail/outdoorsection/${id}`,
      revenue: {
        distribution: (id: FieldValue<string>) =>
          `/detail/outdoorsection/${id}/revenue-distribution`,
        overview: (id: FieldValue<string>) =>
          `/detail/outdoorsection/${id}/revenue-overview`,
        increase: (id: FieldValue<string>) =>
          `/detail/outdoorsection/${id}/revenue-increase`,
      },
      contracts: (id: FieldValue<string>) =>
        `/detail/outdoorsection/${id}/contracts`,
      othercontracts: (id: FieldValue<string>) =>
        `/detail/outdoorsection/${id}/othercontracts`,
      renting: (id: FieldValue<string>) =>
        `/detail/outdoorsection/${id}/renting`,
    },
    organizationuser: {
      overview: (id: FieldValue<string>) => `/detail/organizationuser/${id}`,
      workEngagements: (id: FieldValue<string>) =>
        `/detail/organizationuser/${id}/workengagements`,
      segments: (id: string) => `/detail/organizationuser/${id}/segments`,
      externalClients: (id: string) =>
        `/detail/organizationuser/${id}/externalclients`,
      notificationsettings: (id: string) =>
        `/detail/organizationuser/${id}/notificationsettings`,
      notifications: (id: string) =>
        `/detail/organizationuser/${id}/notifications`,
    },
    parkinglot: {
      overview: (id: FieldValue<string>) => `/detail/parkinglot/${id}`,
      parkingspots: (id: FieldValue<string>) =>
        `/detail/parkinglot/${id}/parkingspots`,
    },
    parkingspot: {
      overview: (id: FieldValue<string>) => `/detail/parkingspot/${id}`,
      revenue: {
        distribution: (id: FieldValue<string>) =>
          `/detail/parkingspot/${id}/revenue-distribution`,
        overview: (id: FieldValue<string>) =>
          `/detail/parkingspot/${id}/revenue-overview`,
        increase: (id: FieldValue<string>) =>
          `/detail/parkingspot/${id}/revenue-increase`,
      },
      renting: (id: FieldValue<string>) => `/detail/parkingspot/${id}/renting`,
      contracts: (id: FieldValue<string>) =>
        `/detail/parkingspot/${id}/contracts`,
      othercontracts: (id: FieldValue<string>) =>
        `/detail/parkingspot/${id}/othercontracts`,
    },
    region: {
      overview: (id: FieldValue<string>) => `/detail/region/${id}`,
      communities: (id: FieldValue<string>) =>
        `/detail/region/${id}/communities`,
    },
    segment: {
      overview: (id: FieldValue<string>) => `/detail/segment/${id}`,
      realestates: (id: FieldValue<string>) =>
        `/detail/segment/${id}/realestates`,
    },
    realestate: {
      overview: (id: FieldValue<string>) => `/detail/realestate/${id}`,
      apartments: (id: FieldValue<string>) =>
        `/detail/realestate/${id}/apartments`,
      brfApartments: (id: FieldValue<string>) =>
        `/detail/realestate/${id}/brfapartments`,
      buildings: (id: FieldValue<string>) =>
        `/detail/realestate/${id}/buildings`,
      commonareas: (id: FieldValue<string>) =>
        `/detail/realestate/${id}/commonareas`,
      industrialpremises: (id: FieldValue<string>) =>
        `/detail/realestate/${id}/industrialpremises`,
      parkingspots: (id: FieldValue<string>) =>
        `/detail/realestate/${id}/parkingspots`,
      outdoorareas: (id: FieldValue<string>) =>
        `/detail/realestate/${id}/outdoorareas`,
      taxation: (id: FieldValue<string>) => `/detail/realestate/${id}/taxation`,
      workengagements: (id: FieldValue<string>) =>
        `/detail/realestate/${id}/workengagements`,
      segmentusers: (id: FieldValue<string>) =>
        `/detail/realestate/${id}/segmentusers`,
    },
    revenueIncrease: {
      overview: (id: FieldValue<string>) =>
        `/detail/revenueincreasecollection/${id}`,
      rows: {
        apartment: (id: FieldValue<string>) =>
          `/detail/revenueincreasecollection/${id}/apartment-rows`,
        apartmentContract: (id: FieldValue<string>) =>
          `/detail/revenueincreasecollection/${id}/apartment-contract-rows`,
        industrialPremises: (id: FieldValue<string>) =>
          `/detail/revenueincreasecollection/${id}/industrial-premises-rows`,
        industrialPremisesContract: (id: FieldValue<string>) =>
          `/detail/revenueincreasecollection/${id}/industrial-premises-contract-rows`,
        outdoorSection: (id: FieldValue<string>) =>
          `/detail/revenueincreasecollection/${id}/outdoor-section-rows`,
        outdoorSectionContract: (id: FieldValue<string>) =>
          `/detail/revenueincreasecollection/${id}/outdoor-section-contract-rows`,
        parkingSpot: (id: FieldValue<string>) =>
          `/detail/revenueincreasecollection/${id}/parking-spot-rows`,
        parkingSpotContract: (id: FieldValue<string>) =>
          `/detail/revenueincreasecollection/${id}/parking-spot-contract-rows`,
      },
    },
    tenant: {
      overview: (id: FieldValue<string>) => `/detail/tenant/${id}`,
      invoices: (id: FieldValue<string>) => `/detail/tenant/${id}/invoices`,
      payments: (id: FieldValue<string>) => `/detail/tenant/${id}/payments`,
      contracts: (id: FieldValue<string>) => `/detail/tenant/${id}/contracts`,
      othercontracts: (id: FieldValue<string>) =>
        `/detail/tenant/${id}/othercontracts`,
      objects: (id: FieldValue<string>) => `/detail/tenant/${id}/objects`,
      brfApartments: (id: FieldValue<string>) =>
        `/detail/tenant/${id}/brfapartments`,
      customerEarningsReport: (id: FieldValue<string>) =>
        `/detail/tenant/${id}/customer-earnings-report`,
    },
    invoice: {
      overview: (id: FieldValue<string>) => `/detail/invoice/${id}`,
    },
    vacancyInvoice: {
      overview: (id: FieldValue<string>) => `/detail/vacancyinvoice/${id}`,
    },
    verificationgroup: {
      overview: (id: FieldValue<string>) => `/detail/verificationgroup/${id}`,
    },
  },
  invoicing: {
    handle: (id: FieldValue<string>) => `/invoicing/handle/${id}`,
    mergepreview: `/invoicing/merge`,
    generate: `/invoicing/generate`,
    massattest: `/invoicing/attest`,
    massattestAll: `/invoicing/attest-all`,
    massattestvacancy: `/invoicing/attest-vacancy`,
    masspause: '/invoicing/pause',
    massresume: '/invoicing/resume',
    masscredit: '/invoicing/credit',
  },
  contracts: {
    create: {
      blockcontract: {
        settings: (tenantId?: string) =>
          `/contracts/create/blockcontracts/settings${tenantId ? `?tenantId=${tenantId}` : ''}`,
        period: () => `/contracts/create/blockcontracts/period`,
      },
      othercontract: {
        settings: ({
          tenantId,
          contentType,
          objectId,
        }: {
          tenantId?: string;
          contentType?: string;
          objectId?: string;
        } = {}) => {
          const params = queryString.stringify(
            { tenantId, contentType, objectId },
            { skipNull: true, skipEmptyString: true }
          );
          return `/contracts/create/othercontract/settings?${params}`;
        },
        period: () => `/contracts/create/othercontract/period`,
      },
      settings: (
        spaceId: FieldValue<string>,
        modelName: ModelName,
        tenantId?: string
      ) =>
        `/contracts/create/${modelName}/${spaceId}/settings${tenantId ? `?tenantId=${tenantId}` : ''}`,
      period: (spaceId: FieldValue<string>, modelName: ModelName) =>
        `/contracts/create/${modelName}/${spaceId}/period`,
    },
    handle: {
      overview: (modelName: ModelName, contractId: FieldValue<string>) =>
        `/contracts/handle/${modelName}/${contractId}/overview`,
      revenue: (modelName: ModelName, contractId: FieldValue<string>) =>
        `/contracts/handle/${modelName}/${contractId}/revenue`,
      invoicing: (modelName: ModelName, contractId: FieldValue<string>) =>
        `/contracts/handle/${modelName}/${contractId}/invoicing`,
      summary: (modelName: ModelName, contractId: FieldValue<string>) =>
        `/contracts/handle/${modelName}/${contractId}/summary`,
    },
  },
  integrationFlow: {
    fortnox: {
      activation: '/integration-flow/fortnox/activation',
      finalize: '/integration-flow/fortnox/finalize',
    },
    vismaEEconomy: {
      activation: '/integration-flow/visma-e-ekonomi/activation',
      finalize: '/integration-flow/visma-e-ekonomi/finalize',
    },
    fastdoc: {
      selectTemplate: (
        integrationUserAgentId: string,
        modelName: ModelName,
        contractId: string
      ) =>
        `/integration-flow/fastdoc/${integrationUserAgentId}/${modelName}/${contractId}/select-template`,
      selectAttachments: (
        integrationUserAgentId: string,
        modelName: ModelName,
        contractId: string
      ) =>
        `/integration-flow/fastdoc/${integrationUserAgentId}/${modelName}/${contractId}/select-attachments`,
      settings: (
        integrationUserAgentId: string,
        modelName: ModelName,
        contractId: string
      ) =>
        `/integration-flow/fastdoc/${integrationUserAgentId}/${modelName}/${contractId}/settings`,
      documents: (
        integrationUserAgentId: string,
        modelName: ModelName,
        contractId: string
      ) =>
        `/integration-flow/fastdoc/${integrationUserAgentId}/${modelName}/${contractId}/documents`,
      parties: (
        integrationUserAgentId: string,
        modelName: ModelName,
        contractId: string
      ) =>
        `/integration-flow/fastdoc/${integrationUserAgentId}/${modelName}/${contractId}/parties`,
    },
  },
  signing: {
    handle: (id: string) => `/signing/handle/${id}`,
    sign: (id: string) => `/signing/sign/${id}`,
  },
  settings: {
    profile: {
      settings: '/settings/profile/settings',
      customization: '/settings/profile/customization',
      notificationsettings: '/settings/profile/notification-settings',
    },
    organization: {
      users: {
        default: '/settings/organization/users',
      },
      notifications: {
        settings: '/settings/organization/notifications/settings',
        customize: '/settings/organization/notifications/customize',
      },
      notificationdistributionoverride: {
        default: '/settings/organization/notifications',
        edit: (event: string) =>
          `/settings/organization/notifications/${event}/edit`,
      },
      segments: {
        default: '/settings/organization/segments',
        create: '/settings/organization/segments/create',
        segment: (id: FieldValue<string>) =>
          `/settings/organization/segments/${id}` as const,
        edit: (id: FieldValue<string>) =>
          `/settings/organization/segments/${id}/edit` as const,
      },
      organizationconfig: {
        default: '/settings/organization/organizations',
        create: '/settings/organization/organizations/create',
        edit: (id: FieldValue<string>) =>
          `/settings/organization/organizations/${id}` as const,
      },
      roles: {
        default: '/settings/organization/roles',
        create: '/settings/organization/roles/create',
        role: (id: FieldValue<string>) =>
          `/settings/organization/roles/${id}` as const,
        edit: (id: FieldValue<string>) =>
          `/settings/organization/roles/${id}/edit` as const,
      },
      workengagements: {
        default: '/settings/organization/work-engagements',
        users: (id: FieldValue<string>) =>
          `/settings/organization/work-engagements/${id}/users`,
        notifications: (id: string) =>
          `/settings/organization/work-engagements/${id}/notifications`,
        dashboard: (id: string) =>
          `/settings/organization/work-engagements/${id}/dashboard`,
      },
      monitoringconfigurations: {
        default: '/settings/organization/monitoring-configurations',
        monitoringconfiguration: (id: FieldValue<string>) =>
          `/settings/organization/monitoring-configurations/${id}` as const,
        edit: (id: FieldValue<string>) =>
          `/settings/organization/monitoring-configurations/${id}/edit` as const,
        approvers: (id: FieldValue<string>) =>
          `/settings/organization/monitoring-configurations/${id}/approvers` as const,
      },
      targetaudiences: {
        industrialpremises: (id: FieldValue<string>) =>
          `/settings/organization/target-audiences/industrial-premises/${id}` as const,
        parkingspot: (id: FieldValue<string>) =>
          `/settings/organization/target-audiences/parking-spot/${id}` as const,
        apartment: (id: FieldValue<string>) =>
          `/settings/organization/target-audiences/apartment/${id}` as const,
      },
      rentableterms: {
        parkingspot: (id: FieldValue<string>) =>
          `/settings/organization/rentable-terms/parking-spot/${id}` as const,
      },
      customercommunicationconfig: {
        default: '/settings/organization/customer-communication-config',
        detail: (id: FieldValue<string>) =>
          `/settings/organization/customer-communication-config/${id}`,
      },
      validationconfig: {
        default:
          '/settings/organization/globalconfigurations/validation-config',
      },
      securityconfig: {
        default: '/settings/organization/globalconfigurations/security-config',
      },
      retroactivityconfig: {
        default:
          '/settings/organization/globalconfigurations/retroactivity-config',
        create: '/settings/organization/retroactivity-config/create',
        edit: '/settings/organization/retroactivity-config/edit',
      },
      security: {
        anonmyization: '/settings/organization/security/anonymization',
      },
      tags: {
        default: '/settings/organization/tags',
      },
      market: {
        terms: {
          overview: '/settings/organization/market/rentable-terms',
          apartment: {
            overview: '/settings/organization/market/rentable-terms/apartment',
            edit: (id: FieldValue<string>) =>
              `/settings/organization/market/rentable-terms/apartment/${id}`,
          },
          industrialPremises: {
            overview:
              '/settings/organization/market/rentable-terms/industrial-premises',
            edit: (id: FieldValue<string>) =>
              `/settings/organization/market/rentable-terms/industrial-premises/${id}`,
          },
          parkingSpot: {
            overview:
              '/settings/organization/market/rentable-terms/parking-spot',
            edit: (id: FieldValue<string>) =>
              `/settings/organization/market/rentable-terms/parking-spot/${id}`,
          },
          outdoorSection: {
            overview:
              '/settings/organization/market/rentable-terms/outdoor-section',
            edit: (id: FieldValue<string>) =>
              `/settings/organization/market/rentable-terms/outdoor-section/${id}`,
          },
        },
        targetAudiences: {
          overview: '/settings/organization/market/target-audiences',

          apartment: {
            overview:
              '/settings/organization/market/target-audiences/apartment',
            edit: (id: FieldValue<string>) =>
              `/settings/organization/market/target-audiences/apartment/${id}`,
          },
          industrialPremises: {
            overview:
              '/settings/organization/market/target-audiences/industrial-premises',
            edit: (id: FieldValue<string>) =>
              `/settings/organization/market/target-audiences/industrial-premises/${id}`,
          },
          parkingSpot: {
            overview:
              '/settings/organization/market/target-audiences/parking-spot',
            edit: (id: FieldValue<string>) =>
              `/settings/organization/market/target-audiences/parking-spot/${id}`,
          },
          outdoorSection: {
            overview:
              '/settings/organization/market/target-audiences/outdoor-section',
            edit: (id: FieldValue<string>) =>
              `/settings/organization/market/target-audiences/outdoor-section/${id}`,
          },
        },
      },
      customercolumntype: {
        default: '/settings/organization/customercolumntypes',
        create: '/settings/organization/customercolumntypes/create',
      },
      integrations: '/settings/organization/integrations',
      integration: (
        applicationId: string,
        serviceId: string,
        objectId?: string
      ) =>
        `/settings/organization/integrations/${applicationId}/${serviceId}?${objectId ? `object_id=${objectId}` : ''}`,
    },

    modules: {
      base: {
        swedishPriceBase: '/settings/modules/base/swedish-price-base',
        swedishReferenceRate: '/settings/modules/base/reference-rate',
        contractcancellations: {
          settings: '/settings/modules/base/contractcancellations/settings',
          setting: (id: FieldValue<string>) =>
            `/settings/modules/base/contractcancellations/settings/${id}`,
          reasons: '/settings/modules/base/contractcancellations/reasons',
        },
      },
      invoicing: {
        accounting: {
          general: '/settings/modules/invoicing/accounting/general',
          accounts: '/settings/modules/invoicing/accounting/accounts',
          method: '/settings/modules/invoicing/accounting/method',
          verifications: '/settings/modules/invoicing/accounting/verifications',
          paymentmethods:
            '/settings/modules/invoicing/accounting/payment-methods',
          dimensions: '/settings/modules/invoicing/accounting/dimensions',
        },
        articles: {
          default: '/settings/modules/invoicing/articles/rent',
          invoice: '/settings/modules/invoicing/articles/invoice',
          consumable: '/settings/modules/invoicing/articles/consumable',
          overriddenArticles: '/settings/modules/invoicing/articles/overridden',
          categories: '/settings/modules/invoicing/articles/categories',
        },
        invoicingsettings: {
          contractinvoicing:
            '/settings/modules/invoicing/invoicingsettings/contractinvoicing',
          paymentterms:
            '/settings/modules/invoicing/invoicingsettings/paymentterms',
        },
        pdfconfig: '/settings/modules/invoicing/pdfconfig',
        vacancies: '/settings/modules/invoicing/vacancies',
        contractinvoicingmessages: '/settings/modules/invoicing/messages',
      },
    },
  },
  login: {
    credentials: '/login/credentials',
    magiclink: '/login/magic-link',
    bankid: '/login/bank-id',
  },
  f: {
    anonymousLinks: '/f',
  },
} as const;

export const ModelNameUrlMap: Partial<
  Record<ModelName, string | ((id?: FieldValue<string>) => string)>
> = {
  [ApartmentConfig.modelName]: Urls.detail.apartment.overview,
  [AddressConfig.modelName]: Urls.detail.address.overview,
  [OrganizationUserConfig.modelName]: Urls.detail.organizationuser.overview,
  [IndustrialPremisesConfig.modelName]: Urls.detail.industrialpremises.overview,
  [OutdoorSectionConfig.modelName]: Urls.detail.outdoorsection.overview,
  [ParkingSpotConfig.modelName]: Urls.detail.parkingspot.overview,
  [CommonAreaConfig.modelName]: Urls.detail.commonarea.overview,
  [RealEstateConfig.modelName]: Urls.detail.realestate.overview,
  [BuildingConfig.modelName]: Urls.detail.building.overview,
  [RegionConfig.modelName]: Urls.detail.region.overview,
  [OutdoorAreaConfig.modelName]: Urls.detail.outdoorarea.overview,
  [ParkingLotConfig.modelName]: Urls.detail.parkinglot.overview,
  [ApartmentContractConfig.modelName]: (id: string) =>
    Urls.detail.contract.overview(ApartmentContractConfig.modelName, id),
  [IndustrialPremisesContractConfig.modelName]: (id: string) =>
    Urls.detail.contract.overview(
      IndustrialPremisesContractConfig.modelName,
      id
    ),
  [OutdoorSectionContractConfig.modelName]: (id: string) =>
    Urls.detail.contract.overview(OutdoorSectionContractConfig.modelName, id),
  [ParkingSpotContractConfig.modelName]: (id: string) =>
    Urls.detail.contract.overview(ParkingSpotContractConfig.modelName, id),
  [OtherContractConfig.modelName]: (id: string) =>
    Urls.detail.contract.overview(OtherContractConfig.modelName, id),
  [BlockContractConfig.modelName]: (id: string) =>
    Urls.detail.contract.overview(BlockContractConfig.modelName, id),
  [CommunityConfig.modelName]: Urls.detail.community.overview,
  [CompanyConfig.modelName]: Urls.detail.company.overview,
  [TenantConfig.modelName]: Urls.detail.tenant.overview,
  [InvoiceConfig.modelName]: Urls.detail.invoice.overview,
  [PriceGroupConfig.modelName]: Urls.detail.pricegroup.overview,
  [SensorSpecificationConfig.modelName]:
    Urls.detail.sensorspecification.overview,
  [SensorConfig.modelName]: Urls.detail.sensor.overview,
  [SegmentConfig.modelName]: Urls.detail.segment.overview,
  [ExternalClientConfig.modelName]: Urls.detail.externalClient.overview,
  [BrfApartmentConfig.modelName]: Urls.detail.brfApartment.overview,
  [BrfCompanyConfig.modelName]: Urls.detail.brfCompany.overview,
  [ApartmentVacancyNotationConfig.modelName]: (id: string) =>
    Urls.detail.vacancyContract.overview(
      ApartmentVacancyNotationConfig.modelName,
      id
    ),
  [IndustrialPremisesVacancyNotationConfig.modelName]: (id: string) =>
    Urls.detail.vacancyContract.overview(
      IndustrialPremisesVacancyNotationConfig.modelName,
      id
    ),
  [OutdoorSectionVacancyNotationConfig.modelName]: (id: string) =>
    Urls.detail.vacancyContract.overview(
      OutdoorSectionVacancyNotationConfig.modelName,
      id
    ),
  [ParkingSpotVacancyNotationConfig.modelName]: (id: string) =>
    Urls.detail.vacancyContract.overview(
      ParkingSpotVacancyNotationConfig.modelName,
      id
    ),
  [VacancyInvoiceConfig.modelName]: (id: string) =>
    Urls.detail.vacancyInvoice.overview(id),
} as const;
